/*
* 基础
*/

/* 样式初始化 */
* {
  margin: 0;
  padding: 0;
}

html {
  text-size-adjust: 100%;
}

body {
  line-height: 1.5;
  font-size: 16px;
  font-family: "Helvetica Neue", Helvetica, Arial,sans-serif;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: normal;
  text-align: center;
}

textarea, input{
  outline: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

a {
  display: inline-block;
  line-height: inherit;
  color: inherit;
  outline: 0;
  text-decoration: none;
  cursor: pointer;
  &:link, &:visited, &:active, &:hover {
    color: inherit;
  }
  img {
    border: 0;
  }
}

li {
  list-style: none;
}


::-webkit-scrollbar
{
  width: 6px;
  height: 6px;
}
::-webkit-scrollbar-track-piece
{
  background-color: #ebeef3;
  -webkit-border-radius: 4px;
}
::-webkit-scrollbar-thumb:vertical
{
  height: 32px;
  background-color: #d8dce4;
  -webkit-border-radius: 4px;
}
::-webkit-scrollbar-thumb:horizontal
{
  width: 32px;
  background-color: #d8dce4;
  -webkit-border-radius: 4px;
}