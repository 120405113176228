/* 设置皮肤样式 */

/* 封面背景及主题背景，可以设置图片 */
$album_background: #0091e4;
$theme_background: #f9fcff;

/* 导航及tab相关颜色 */
$color_nav_background: #fff;
$color_nav_font: #333;
$color_nav_active_background: #e5f4ff;
$color_nav_active_font: #fff;

/* 输入框颜色 */
$color_chat_editor_background: #e5f4ff;
$color_chat_editor_button: #0091e4;

/* 基础按钮颜色 */
$color_base_font: #333;
$color_error: #ed0e0e;
$color_tip_background: #e0e0e0;
$color_tip_font: #ccc;
$color_button_font: #fff;
$color_button_primary: #0091e4;
$color_button_warn: #f04d64;

$color_input_dark: #333;
$color_input_light: #999;

/* 设置颜色变量 */
$color_white: #fff;

/* 顶部导航高度 */
$height_nav: 3.6rem;
$height_tab: 3.6rem;
$height_room_banner: 12rem;
$height_room_tabs: 3rem;

$height_editor: 4rem;

/* 消息变量 */
$msg_bg_you: #5cacde;
$msg_tx_you: #fff;
$msg_bg_me: #e5f4ff;
$msg_tx_me: #666;

@import './common/base.css';
@import './common/grid.css';
@import './common/weui.css';
@import './common/module.css';
@import './common/unit.css';
@import './common/animation.css';
